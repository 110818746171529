.login-wrap {
    background: rgba(75,167,141, 0.5);
    padding: 70px;
    position: relative;
}

.contact-form input {
    width: 100%;
    height: 45px;
    margin-top: 20px;
    padding-left: 10px;
    background: #4ba78d;
    opacity: 1;
    border-radius: 5px;
    border: 1px solid rgba(255,255,255,.3);
    outline: none;
}

.form-control {
    margin-top: 20px;
    font-size: 16px;
    background: #fff;
}

select.form-control:not([size]):not([multiple]) {
    height: 50px;
    border-radius: 5px;
    color: #333;
    font-size: 14px;
    background:#4ba78d;
    position: relative;
    border: 1px solid rgba(255,255,255,.3);
    outline: none;
    box-shadow: none;
}
.login-area form input:focus,
.login-area form select:focus,
.login-area form textarea:focus{
    border: 1px solid rgba(255,255,255,.3);
    box-shadow: none;
    outline: none;
}
.contact-textarea {
    width: 100%;
    height: 170px;
    margin-top: 20px;
    padding-left: 10px;
    padding-top: 10px;
    border: none;
    border-radius: 5px;
    background: #4ba78d;
    border: 1px solid rgba(255,255,255,.3);
    outline: none;
}

.contact-form button {
    background: none;
    border: none;
    padding: 10px 30px;
    background: #4ba78d;
    color: #333;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    border: 1px solid rgba(255,255,255,.3);
    border-radius: 40px;
}
.contact-form button:focus{
    outline: none;
}

.contact-form button:hover {
    background:#4ba78d;
}
::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */

    color: #333;
    font-size: 14px;
}
::-moz-placeholder {
    /* Firefox 19+ */

    color: #333;
    font-size: 14px;
}
:-ms-input-placeholder {
    /* IE 10+ */

    color: #333;
    font-size: 14px;
}
:-moz-placeholder {
    /* Firefox 18- */
    color: #333;
    font-size: 14px;
}
option {
    color: #333;
}

@media(max-width:767px){
    .login-wrap {
        padding: 40px 10px;
    }
}

.contact-form p{
    color: red;
}