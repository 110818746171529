.site-footer {
    background: url(../../images/slider/2.jpg);
    text-align: center;
    padding: 120px 0;
    position: relative;
    overflow: hidden;
  }

  .site-footer.s2{
    background: url(../../images/slider/4.jpg);
  }
  
  .site-footer .text{
    text-align: center;
    background: rgba(75,167,141,.8);
    width: 310px;
    height: 310px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: auto;
    border-radius: 50%;
    position: relative;
}
.site-footer .text:before {
    position: absolute;
    left: -6px;
    top: -7px;
    width: 104%;
    height: 104%;
    content: "";
    border: 1px solid rgba(255,255,255,.3);
    border-radius: 50%;
}
  @media (max-width: 1199px) {
    .site-footer {

    }
  }
  
  @media (max-width: 991px) {
    .site-footer {

    }
  }
  
  .site-footer:before {
    content: "";
    background-color: rgba(0,0,0,.4);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .site-footer h2 {
    font-size: 40px;
    color: #eae8e8;
    margin: 0 0 0.3em;
    display: inline-block;
    position: relative;
    z-index: 2;
    font-family: 'Great Vibes', cursive;
  }
  
  @media (max-width: 767px) {
    .site-footer h2 {
      font-size: 30px;
      padding: 0 40px;
    }
    .site-footer .text {
      width: 255px;
      height: 255px;
  }
  }
  
  @media (max-width: 450px) {
    .site-footer h2 {
      font-size: 40px;
      padding: 0;
    }
  }
  .site-footer p {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1em;
    margin: 0;
    color: #eae8e8;
    position: relative;
    z-index: 2;
  }
  
  @media (max-width: 767px) {
    .site-footer p {
      font-size: 20px;
      font-size: 1.25rem;
    }
  }