
.header ul{
    list-style: none;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
}
.header ul li {
    position: relative;
}
.header {
    background: rgba(75,167,141,.5);
    border-bottom: 1px solid rgba(255,255,255,.1);
    position: absolute;
    width: 100%;
    z-index: 99;
}
.header ul li a{
    display: block;
    padding: 35px 25px;
    color:#fff;
    text-decoration: none;
}
.header ul li a:hover{
    color: #63daba;
}
.logo{
    margin-top: 30px;
}

.logo h2 a{
    color: #fff;
}
.header-menu ul li{
    position: relative;
}

.header-menu .submenu {
    position: absolute;
    left:18px;
    top: 140%;
    z-index: 999;
    width: 185px;
    padding: 0;
    background: #fff;
    text-align: left;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
    visibility: hidden;
}

.header-menu ul>li:hover>.submenu {
    top: 100%;
    opacity: 1;
    visibility: visible;
}
.header-menu .submenu{
    display: unset;
}

.header-menu .submenu li{
    padding: 0px;
}
.header-menu .submenu li a{
    padding: 15px;
    display: block;
    color:#444;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebebeb;
}

@media(max-width:991px){
    .logo {
        margin-top: 20px;
        margin-bottom: 15px;
    }
}