.section-title h2{
    text-align: center;
    margin-bottom: 60px;
    font-size:60px;
    position: relative;
    display: inline-block;
}
.section-title h2:before{
    position: absolute;
    top: -80px;
    left: 50%;
    content: "";
    background: url(../../images/section-title.png);
    width: 100px;
    height: 80px;
    transform: translateX(-50%);
}
.section-title{
    text-align: center;
}

@media(max-width:767px){
    .section-title h2 {
        margin-bottom: 30px;
        font-size: 30px;
    }
}